
import { Component, Vue } from "vue-property-decorator";
import LogoSingle from "@/components/global/LogoSingle.vue";
import StepTracker from "@/components/request/StepTracker.vue";
import SearchService from "@/components/request/SearchService.vue";

@Component({
  components: {
    LogoSingle,
    StepTracker,
    SearchService,
    IdentifyUser: () => import("@/components/safeHouse/IdentifyUser.vue"),
    SendRequest: () => import("@/components/safeHouse/SendRequest.vue"),
    EndRequest: () => import("@/components/safeHouse/EndRequest.vue")
  }
})
export default class NewRequest extends Vue {
  private mounted() {
    this.$store.dispatch("setStateToTwo");
    this.$store.dispatch("cleanTicket");
    window.scroll(0, 0);
  }
}
